@import "./core";
@import "../assets/scss/styles";
@import "./angular/libs/toastr.component";
@import "./base/pages/ui-colors";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import './base/bootstrap-extended/variables';

// source code pro font
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");


html {
  font-size: 16px;
}

:root {
  --primary-color: #4396dd;
  --primary-color-var: 67, 150, 221;
  --secondary-color: #f5bd3a;
  --secondary-color-var: 245, 189, 58;
  --primary: #000000;
  --gray-shades-10: rgba(0, 0, 0, 0.1);
  --gray-shades-20: rgba(0, 0, 0, 0.2);
  --gtr-color-base-black-20: rgba(0, 0, 0, 0.2);
  --secondary: #8d8d8d;
  --default: #555555;
  --btn-seconday: #f1f1f1;
  --blue: #4396dd;
  --lightgrey: #d5d5d5;
  --green: #76b21b;
  --green2: #33b462;
  --phone: #4396dd;
  --online: #f5bd3a;
  --in-person: #54b51d;
  --text-color: #000000;
  --secondary-text-color: '#000000';
  --gray-shades-80: rgba(0, 0, 0, 0.8);
  --sidebar-image-url: url("../assets/images/rocket.png");

  // root variables for visualize as brand offer banner
  --ofr-bnr-primary-clr: #4396dd;
  --ofr-bnr-text-clr: #ffffff;

  //for gettrx library
  --gtr-color-base-primary-pressed: rgba(var(--primary-color-var), 1);
  --gtr-color-base-primary-hover: rgba(var(--primary-color-var), 1);
  --gtr-color-base-primary-100: var(--primary-color);
  --gtr-color-base-primary-80: rgba(var(--primary-color-var), 0.8);
  --gtr-color-base-primary-60: rgba(var(--primary-color-var), 0.6);
  --gtr-color-base-primary-40: rgba(var(--primary-color-var), 0.4);
  --gtr-color-base-primary-20: rgba(var(--primary-color-var), 0.2);
  --gtr-color-base-primary-10: rgba(var(--primary-color-var), 0.1);
  --gtr-color-base-primary-5: rgba(var(--primary-color-var), 0.05);
  --gtr-color-font-inverse-primary: var(--text-color);
  --gtr-color-font-inverse-secondary: var(--secondary-text-color);

  //for gettrx libary secondary color
  --gtr-color-base-secondary-pressed: rgba(var(--secondary-color-var), 1);
  --gtr-color-base-secondary-hover: rgba(var(--secondary-color-var), 1);
  --gtr-color-base-secondary-base: var(var(--secondary-color));
  --gtr-color-base-secondary-100: rgba(var(--secondary-color-var), 1);
  --gtr-color-base-secondary-80: rgba(var(--secondary-color-var), 0.8);
  --gtr-color-base-secondary-60: rgba(var(--secondary-color-var), 0.6);
  --gtr-color-base-secondary-40: rgba(var(--secondary-color-var), 0.4);
  --gtr-color-base-secondary-20: rgba(var(--secondary-color-var), 0.2);
  --gtr-color-base-secondary-10: rgba(var(--secondary-color-var), 0.1);
  --gtr-color-base-secondary-5: rgba(var(--secondary-color-var), 0.05);
}

html {
  min-height: 100vh;
  background: white;
}

body {
  font-size: 1rem;
  font-family: "Roboto", sans-serif !important;

  overflow-x: hidden;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  //  overflow-y: hidden;
}


.new-sidebar-offer {
  position: relative;
}

.new_offer_content_web {
  width: 100%;
  max-width: 100%;
  min-height: 720px;
}


ng-multiselect-dropdown *:focus {
  border-bottom: solid 1px blue;
}

* {
  outline: none !important;
  font-family: "Roboto" !important;
}

.text-color {
  color: var(--text-color) !important;
}

.theme-text {
  color: var(--primary-color) !important;
}

.theme-svg {
  fill: var(--primary-color) !important;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.fstyle-normal {
  font-style: normal !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: "Roboto", sans-serif !important;
}

h1,
.h1 {
  font-size: 1.9rem;
}

.rounded-1 {
  border-radius: 10px;
}

.rounded-2 {
  border-radius: 15px;
}

.rounded-5 {
  border-radius: 5px;
}

.rounded-8 {
  border-radius: 8px;
}

.sub-heading {
  font-size: 1.1rem;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 600 !important;
}

.fw5-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.mw-120 {
  min-width: 130px !important;
}

.fs-14 {
  font-size: 0.9rem !important;
}

.fs-10 {
  font-size: 0.6rem !important;
}

.fs-11 {
  font-size: 0.68rem !important;
}

.fs-12 {
  font-size: 0.75rem !important;
}

.fs-13 {
  font-size: 0.8rem !important;
}

.fs-22 {
  font-size: 1.35rem !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

app-accept-payments {
  width: 100%;
}

app-business-type {
  width: 100%;
}

hr.separator {
  border-color: var(--gtr-color-base-black-10);
  opacity: unset;
  margin: 12px -18px 16px;
}

// .create_account_heading{
//   padding: 20px 0px;
// }
// custom margins
.mbb-1 {
  margin-bottom: 0.25rem !important;
}

.mbb-2 {
  margin-bottom: 0.5rem !important;
}

.mbb-3 {
  margin-bottom: 1rem !important;
}

.mbb-4 {
  margin-bottom: 1.5rem !important;
}

.mbb-5 {
  margin-bottom: 3rem !important;
}

.mtt-1 {
  margin-top: 0.25rem !important;
}

.mtt-2 {
  margin-top: 0.5rem !important;
}

.mtt-3 {
  margin-top: 1rem !important;
}

.mtt-4 {
  margin-top: 1.5rem !important;
}

.mtt-5 {
  margin-top: 3rem !important;
}

.mll-1 {
  margin-left: 0.25rem !important;
}

.mll-2 {
  margin-left: 0.5rem !important;
}

.mll-3 {
  margin-left: 1rem !important;
}

.mll-4 {
  margin-left: 1.5rem !important;
}

.mll-5 {
  margin-left: 3rem !important;
}

.mrr-1 {
  margin-right: 0.25rem !important;
}

.mrr-2 {
  margin-right: 0.5rem !important;
}

.mrr-3 {
  margin-right: 1rem !important;
}

.mrr-4 {
  margin-right: 1.5rem !important;
}

.mrr-5 {
  margin-right: 3rem !important;
}

.pll-0 {
  padding-left: 0 !important;
}

.pll-1 {
  padding-left: 0.25rem !important;
}

.pll-2 {
  padding-left: 0.5rem !important;
}

.pll-3 {
  padding-left: 1rem !important;
}

.pll-4 {
  padding-left: 1.5rem !important;
}

.pll-5 {
  padding-left: 3rem !important;
}

.prr-1 {
  padding-right: 0.25rem !important;
}

.prr-2 {
  padding-right: 0.5rem !important;
}

.prr-3 {
  padding-right: 1rem !important;
}

.prr-4 {
  padding-right: 1.5rem !important;
}

.prr-5 {
  padding-right: 3rem !important;
}

.ptt-1 {
  padding-top: 0.25rem !important;
}

.ptt-2 {
  padding-top: 0.5rem !important;
}

.ptt-3 {
  padding-top: 1rem !important;
}

.ptt-4 {
  padding-top: 1.5rem !important;
}

.ptt-5 {
  padding-top: 3rem !important;
}

.pbb-1 {
  padding-bottom: 0.25rem !important;
}

.pbb-2 {
  padding-bottom: 0.5rem !important;
}

.pbb-3 {
  padding-bottom: 1rem !important;
}

.pbb-4 {
  padding-bottom: 1.5rem !important;
}

.pbb-5 {
  padding-bottom: 3rem !important;
}

.pp-0 {
  padding: 0 !important;
}

.pp-1 {
  padding: 0.25rem !important;
}

.pp-2 {
  padding: 0.5rem !important;
}

.pp-3 {
  padding: 1rem !important;
}

.pp-1 {
  padding: 0.25rem !important;
}

.pp-2 {
  padding: 0.5rem !important;
}

.pp-3 {
  padding: 1rem !important;
}

.pp-4 {
  padding: 1.5rem !important;
}

.pp-5 {
  padding: 3rem !important;
}

textarea.form-control.pll-0 {
  padding-left: 0 !important;
}

// end
.border-white {
  border: 1px solid white;
}

.mh-440 {
  max-height: 440px;
}

.mh-450 {
  max-height: 450px;
}

.mh-400 {
  min-height: 400px;
}

.mh-240 {
  min-height: 240px;
}

.mxh-250 {
  max-height: 250px;
}

.mxh-405 {
  max-height: 405px;
}

.mxh-400 {
  max-height: 400px;
}

.mxh-260 {
  max-height: 260px;
}

.mwx-100 {
  min-width: 100px;
}

.mwx-86 {
  min-width: 86px;
}

.mw-200 {
  min-width: 200px;
}

.top-0 {
  top: 0 !important;
}

.top-10 {
  top: 10px !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-10 {
  bottom: 10px !important;
}

.bottom-35 {
  bottom: 35px !important;
}

.right-0 {
  right: 0 !important;
}

.right-10 {
  right: 10px !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.text-primary {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.text-default {
  color: var(--default) !important;
}

.text-blue {
  color: var(--primary-color) !important;
}

.text-70 {
  color: #707070 !important;
}

.wrapped-text gtr-input {
  .gtr-form-element-input.isonlyview {
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.btn {
  border-radius: 5px;
  font-size: 0.9rem;
  box-shadow: none !important;
  text-transform: capitalize !important;
  border-color: rgba(67, 149, 221, 0.055) !important;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  background: var(--primary-color) !important;
  color: var(--text-color) !important;
}

.btn-secondary,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active {
  background: var(--secondary-color) !important;
  color: var(--secondary-text-color) !important;
}

.btn-success {
  background: var(--green2);
}

a {
  color: var(--blue);
}

.lightgrey {
  color: var(--lightgrey);
}

.bg-green {
  color: var(--green);
}

.bg-blue {
  background: var(--blue) !important;
}

$labelClr: "#201f1f";

.header-navbar .navbar-container ul.navbar-nav li>a.nav-link {
  padding: 0 !important;
}

/*
 stats card scroll width hide
 */
.row .col-12 ul::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: "none";
  scrollbar-width: "none";
}

.row .col-12 gtr-select ul::-webkit-scrollbar {
  display: block;
}

ul.nav.navbar-nav li a {
  color: var(--primary);
  font-size: 0.9rem !important;
  font-family: "Roboto", sans-serif;
  /*
  fixed navbar ui isuse for merchant
  */
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
}

ul {
  -ms-overflow-style: "none";
  scrollbar-width: "none";
}

/*
 Search container ui css
 */

#btn-container,
#btn-box-container {
  -ms-overflow-style: "none";
  scrollbar-width: "none";
}

// To fix Iphone z-index issue with filter sekect boxes
.dashboard-analytics-wrapper {
  position: relative;
}

#btn-container::-webkit-scrollbar,
#btn-box-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.scrolled nav {
  opacity: 0.8;
}


.filter-responsive-scroll {
  position: relative;
}

.filter-responsive-scroll.fade-left:after {
  content: "";
  position: absolute;
  top: 0;
  right: 22px;
  width: 120px;
  height: 38px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(238, 238, 238, 0)), to(rgba(238, 238, 238, 0.8)));
  background: linear-gradient(to right, rgba(238, 238, 238, 0), rgba(238, 238, 238, 0.8));
  pointer-events: none;
  border-radius: 6px;
  z-index: 1;
}

.filter-responsive-scroll.fade-right:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 38px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(238, 238, 238, 0)), to(rgba(238, 238, 238, 0.8)));
  background: linear-gradient(to left, rgba(238, 238, 238, 0), rgba(238, 238, 238, 0.8));
  pointer-events: none;
  border-radius: 6px;
  z-index: 1;
}


.filter-responsive-scroll.fade-top:after {
  top: 378px;
}

.skeleton-margin-bottom {
  margin-bottom: 12px;
}

.sk-prospect-created-date {
  margin-bottom: 12px !important;
  margin: 0 auto;
}

.sk-prospect-created-date:last-child {
  margin-bottom: 0px !important;
}

.skeleton-margin-bottom:last-child {
  margin-bottom: 0px;
}

.pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation-delay: 0.5s;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

[title] {
  position: relative;
}

body.touched .gtr-stats-card-value-label [title]>* {
  user-select: none;
}

body.touched .gtr-stats-card-value-label [title]:hover>* {
  user-select: auto
}

body.touched .gtr-stats-card-value-label [title]:hover:after {
  position: absolute;
  top: 100%;
  right: -10%;
  content: attr(title);
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  box-shadow: 1px 1px 3px;
  padding: 0.3em;
  font-size: 12px;
  z-index: 1;
}

.skeleton-margin-bottom:last-child {
  margin-bottom: 0px;
}


.sk-float-right {
  float: right;
}

.sk-float-right-mr-10 {
  float: right;
  margin-right: 10px;
}


.sk-mr-10 {
  margin-right: 10px;
}

.sk-float-left {
  float: left;
}

.sk-float-none {
  margin: 0 auto;
}

.pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation-delay: 0.5s;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

[title] {
  position: relative;
}

body.touched .gtr-stats-card-value-label [title]>*,
body.touched .txn-content [title]>* {
  user-select: none;
}

body.touched .gtr-stats-card-value-label [title]:hover>*,
body.touched .txn-content [title]:hover>* {
  user-select: auto;
}

body.touched .gtr-stats-card-value-label [title]:hover:after,
body.touched .txn-content [title]:hover:after {
  position: absolute;
  top: 100%;
  right: -10%;
  content: attr(title);
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  box-shadow: 1px 1px 3px;
  padding: 0.3em;
  font-size: 12px;
  z-index: 1;
}

.details-page-loading-wrapper {
  height: 100%;
  position: relative;
}

// details page loading

.details--page--loading:before {
  content: " ";
  width: calc(100%);
  height: calc(100%);
  background: rgb(255 255 255 / 73%);
  z-index: 2;
  display: flex;
  position: absolute;
}

.details--page--loading:after {
  content: " ";
  width: 2.75em;
  height: 2.75em;
  margin-right: 0.5em;
  border-radius: 50%;
  display: inline-block;
  border: 4px solid var(--primary-color);
  border-color: var(--primary-color) transparent var(--primary-color) transparent;
  animation: loading-spinner-animation 1s linear infinite;
  position: absolute;
  z-index: 3;
  margin: 0 auto;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.section-disabled {
  position: relative;
  pointer-events: none;

  &::after {
    content: " ";
    width: calc(100%);
    height: calc(100%);
    background: rgb(255 255 255 / 73%);
    z-index: 9;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
}




// graph loading

.graphs .graph-col {
  min-height: 500px;
  position: relative;
}

.graph--loading:before {
  content: " ";
  width: calc(100% - 34px);
  height: calc(100% - 40px);
  background: rgb(255 255 255 / 73%);
  z-index: 1;
  display: flex;
  position: absolute;
  border: 1px solid var(--gray-shades-10, rgba(0, 0, 0, 0.1));
  border-radius: 6px;
}

.graph--loading:after {
  content: " ";
  width: 2.75em;
  height: 2.75em;
  margin-right: 0.5em;
  border-radius: 50%;
  display: inline-block;
  border: 4px solid var(--primary-color);
  border-color: var(--primary-color) transparent var(--primary-color) transparent;
  animation: loading-spinner-animation 1s linear infinite;
  position: absolute;
  z-index: 2;
  margin: 0 auto;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

/*Btn css --- */

/* Button loading state */

/* Animation
source: https://loading.io/css/
*/

.button--loading:before {
  content: " ";
  width: 0.75em;
  height: 0.75em;
  margin-right: 0.5em;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: loading-spinner-animation 1s linear infinite;
}

@keyframes loading-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*Table css --- */

.gtr-table-wrapper table thead th,
.gtr-table-wrapper .gtr-table thead th {
  min-width: 120px;
}

.gtr-table-wrapper table thead th:has(gtr-checkbox) {
  min-width: auto !important;
}

.gtr-table-wrapper .gtr-table td.gtr-table-more-button {
  padding-right: 10px !important;
}

.gtr-table-wrapper .gtr-table td {
  padding-right: 24px !important;
  color: var(--gray-shades-80) !important;
}

.gtr-header-sorting-wrapper {
  padding-left: 5px;
}

td .approved {
  color: #009e47;
  font-weight: 700;
  font-size: 15px;
  text-transform: capitalize;
}

td .declined {
  color: rgba(75, 70, 92, 1);
  font-weight: 700;
  font-size: 15px;
  text-transform: capitalize;
}

td .withdrawn {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
  font-size: 15px;
  text-transform: capitalize;
}

app-accept-payments,
app-select-hardware,
app-taking-payments,
app-shoping-cart-plugin,
app-traded-company,
app-business-website,
app-business-category,
app-start-business,
app-signup-offer-view,
app-business-incorporated,
app-where-business-incorporated,
app-verify-business,
app-sales-volume,
app-funds-settelment,
app-sales-volume,
app-personal-bankruptcy,
app-auth-onboarding,
app-product-received,
app-dcc-compliant,
app-general-info,
app-product-services,
app-payment-percentage,
app-summary,
app-payment-gateways,
app-store-data,
app-accept-merchant-agreement {
  width: 100%;
}

select.form-control {
  background-image: url(../assets/images/hardwares-img/select-icon.png) !important;
  background-size: auto !important;
  background-position: 100% 18px !important;
}

.form-control.percentage {
  background-image: url(../assets/images/hardwares-img/percentage.png) !important;
  background-size: auto !important;
  background-position: 100% 18px !important;
  background-repeat: no-repeat;
}

.form-control.email-control {
  background-color: inherit !important;
  // background-image: url(assets/images/pages/email.png) !important;
  background-position: 100% 15px !important;
  background-size: auto !important;
  background-repeat: no-repeat !important;
}

// .form-control.phone_input {
//   background-color: inherit !important;
//   background-image: url(assets/images/pages/smartphone.png) !important;
//   background-position: 100% 15px;
//   background-size:auto !important;
//   background-repeat: no-repeat !important;
// }
.PasswordRow.form-group .was-validated .form-control:invalid,
.PasswordRow.form-group .form-control.is-invalid {
  background: none;
}

.PasswordRow.form-group:after {
  content: "";
  background-color: inherit !important;
  background-image: url(../assets/images/pages/key.png) !important;
  background-position: 100% 11px;
  background-repeat: no-repeat !important;
  background-size: auto !important;
  position: absolute;
  z-index: 99;
  height: 39px;
  width: 35px;
  top: -1px;
  right: 0;
  background-repeat: no-repeat;
}

.emailicon-row.form-group .was-validated .form-control:invalid,
.emailicon-row.form-group .form-control.is-invalid {
  background: none;
}

.emailicon-row.form-group:after {
  content: "";
  background-color: inherit !important;
  background-image: url(../assets/images/pages/email.png) !important;
  background-position: 100% 11px;
  background-repeat: no-repeat !important;
  background-size: auto !important;
  position: absolute;
  z-index: 99;
  height: 39px;
  width: 35px;
  top: 4px;
  right: 0;
  background-repeat: no-repeat;
}

.smartphone.form-group:after {
  content: "";
  background-color: inherit !important;
  background-image: url(../assets/images/pages/smartphone.png) !important;
  background-position: 100% 11px;
  background-repeat: no-repeat !important;
  background-size: auto !important;
  position: absolute;
  z-index: 99;
  height: 42px;
  width: 35px;
  top: -7px;
  right: 0;
  background-repeat: no-repeat;
}

ul.nav.navbar-nav.flex-row.justify-content-around {
  justify-content: flex-start !important;
}

ul.nav.navbar-nav.flex-row.justify-content-around li.nav-item {
  margin-left: 10px;
  margin-right: 80px;
}

.sales-volume-page .form-group {
  margin-bottom: 2rem;
}

.main.comman-main .form-control {
  font-size: 1.05rem;
}

.main.comman-main label {
  font-size: 1.05rem;
  font-weight: 500;
}

.main.comman-main input:focus~label,
.main.comman-main input:valid~label {
  top: -21px;
  font-weight: 500;
}

.payment-percentage-page .pa_name span {
  font-weight: 400;
}

.payment-percentage-page .pa_name {
  font-size: 1.05rem;
  font-weight: 400;
  min-width: 200px;
}

ul.comman-listing li {
  font-size: 1.05rem;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
}

span.percent_age {
  font-size: 1.05rem;
  font-weight: 800;
}

.accept-payment-page .pa_name {
  align-items: center;
  font-size: 1.05rem;
  font-weight: 500;
}

.accept-payment-page .card .card-row .col-7.text-start {
  max-width: 100%;
  flex: auto;
}

.main.authentication-screens .small-labels.onboard-pages label,
.main.authentication-screens .small-labels.onboard-pages .form-control {
  font-size: 0.9rem !important;
}

.main.authentication-screens .small-labels.onboard-pages input:focus~label,
.main.authentication-screens .small-labels.onboard-pages input:valid~label {
  top: -16px;
}

.taking-payment-page .card.comman {
  min-height: 352px;
  padding: 0 5px 20px;
}

.business-principles .form-group {
  margin-bottom: 2rem;
}

.summary-page .scard-wrap {
  padding: 12px 12px;
  border: 1px solid #d6d6d6;
  font-family: "Roboto", sans-serif;
}

.summary-page h6 {
  font-family: "Roboto", sans-serif !important;
}

app-signup-offer-view h3 {
  font-size: 1.3rem;
}

.summary-page .label-btn.btn-default {
  background: #d6d6d6;
  display: inline-block;
  padding: 2px 10px;
  line-height: 22px;
}

span.offer_val_web {
  font-size: 1.1rem !important;
  min-width: 80px;
}

// COMMENTED FOR NOW TO ADD GAP BETWEEN BUBBLE AND TILES IN OFFER BAR
// app-signup-offer-view .sidebar-offer p.desktop-content {
//   margin-bottom: 0 !important;
// }

app-signup-offer-view .new-sidebar-offer p.desktop-content {
  margin-bottom: 0 !important;
}

.principles-col .subtitle {
  border-bottom: 1px solid #d6d6d6;
}

app-signup-offer-view .sidebar-offer .sidev1 {
  // margin: 2px 0 !important;
  margin: 8px 0 !important;
}

app-signup-offer-view .sidebar-offer h3.text-white {
  // margin-top: 10px !important;
  margin-top: 20px !important;
}

app-signup-offer-view .sidebar-offer h3.text-white+p {
  margin-bottom: 10px !important;
}

.offers-outer-wrapper {
  margin-top: 8px;
}

.text-price {
  color: #5e5873;
  font-family: "Roboto", sans-serif;
}

.price-bottom-row {
  border-top: 1px solid #ede5e5;
}

.note-title {
  background: #cfe8fb;
}

.modal .modal-header .close {
  box-shadow: none;
  background: transparent;
  transform: inherit !important;
  font-size: 50px;
  color: black;
  right: 5px;
  top: 10px;
}

.modal-open .sidebar-offer {
  z-index: 999 !important;
}

.multiselect-dropdown .dropdown-btn {
  padding-left: 0 !important;
  border-radius: 0 !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  padding-left: 0 !important;
  box-shadow: none !important;
  font-weight: 600;
}

.multiselect-item-checkbox input[type="checkbox"]+div:after {
  border-color: #66bd07 !important;
}

.hardware-col .card-title h5 {
  min-height: 80px;
}

.select-hardware-page .page-area .row {
  margin-left: 0;
  margin-right: 0;
}

.product-img-1 {
  min-height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-img-1 img {
  max-height: 100px;
}

.main-menu.menu-light .navigation>li.active>a {
  box-shadow: none;
  background: var(--primary-color);
}

.dropdown-list {
  border: 1px solid #e3e3e3 !important;
  border-radius: 10px !important;
  box-shadow: 0 1px 15px rgb(149 149 149 / 35%) !important;
}

.sidebar-offer.viewsdofr {
  display: block;
}

.start-business-page select.form-control {
  padding-left: 0 !important;
  margin-left: 0 !important;
}

// select dropdown floating label
.state-dropdown-btn span.dropdown-btn:before {
  position: absolute;
  top: 7px;
  content: "State";
  left: 0;
  transition: all 0.2s ease;
}

.state-dropdown-btn ng-multiselect-dropdown.active span.dropdown-btn:before {
  top: -16px;
}

.state-dropdown-btn span.selected-item a {
  display: none;
}

.state-dropdown-btn span.selected-item {
  padding-top: 7px !important;
}

.state-dropdown-btn span.ng-star-inserted,
.comman-dropdown span.ng-star-inserted {
  display: none !important;
}

.state-dropdown-btn span.selected-item-container.ng-star-inserted,
.comman-dropdown span.selected-item-container.ng-star-inserted {
  display: block !important;
}

.year-dropdown-btn span.dropdown-btn:before {
  position: absolute;
  top: 7px;
  content: "Year";
  left: 0;
  transition: all 0.2s ease;
}

.year-dropdown-btn ng-multiselect-dropdown.active span.dropdown-btn:before {
  top: -16px;
}

.year-dropdown-btn span.selected-item a {
  display: none;
}

.year-dropdown-btn span.selected-item {
  padding-top: 7px !important;
}

.month-dropdown-btn span.dropdown-btn:before {
  position: absolute;
  top: 7px;
  content: "Month";
  left: 0;
  transition: all 0.2s ease;
}

.month-dropdown-btn ng-multiselect-dropdown.active span.dropdown-btn:before {
  top: -16px;
}

.month-dropdown-btn span.selected-item a {
  display: none;
}

.month-dropdown-btn span.selected-item {
  padding-top: 7px !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  top: 13px !important;
  border: none !important;
  background: url(../assets/images/hardwares-img/select-icon.png) no-repeat;
  background-position: right;
  height: 15px;
  width: 17px;
  position: absolute !important;
  right: 0;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect--active .dropdown-multiselect__caret {
  transform: inherit !important;
}

.multiselect-dropdown .dropdown-list {
  margin-top: 2px !important;
}

span.dropdown-multiselect__caret {
  padding-right: 0 !important;
}

// select dropdown floating label
.hardware-col .card-title.mb-2,
.hardware-col .card-title.mb-4 {
  margin-bottom: 0 !important;
}

.start-business-page .page-area {
  margin-top: 80px !important;
}

.start-business-page .multiselect-dropdown .dropdown-btn {
  font-size: 1rem;
}

.tc-text {
  position: relative;
  top: 10px;
}

.offers-outer-wrapper.custom-scroll {
  border-radius: 0px !important;
}

.modal-body {
  background: #f1f1f1;
}

.modal-body .custom-scroll {
  margin-top: 25px;
}

.sales-volume-page .page-area input:valid,
.sales-volume-page .page-area input:focus {
  padding-left: 18px;
  background: url(../assets/images/dollar-icon.png) no-repeat !important;
  background-position: 0 16px !important;
  font-family: "Roboto", sans-serif;
}

.d-flex.flex-row.text-center button.back_button {
  margin-right: 15px !important;
}

.d-flex.flex-row.text-center .btn.btn-primary {
  margin-left: 0 !important;
}

.businessincorporated-page .d-flex.flex-row.text-center button.back_button {
  margin-right: 0px !important;
}

.business-website-page .other-form {
  max-width: 380px !important;
  width: 100%;
}

.business-principles .back_button {
  margin-left: 0 !important;
}

.modal-open .horizontal-layout .header-navbar {
  z-index: 1;
}

.eye-password {
  position: absolute;
  right: 0px;
  top: 13px;
  font-size: 15px;
  cursor: pointer;
  z-index: 5;
  height: 26px;
  width: 26px;
}

.eye-password {
  position: absolute;
  right: 0px;
  top: 13px;
  font-size: 15px;
  cursor: pointer;
  z-index: 5;
  height: 26px;
  width: 26px;
}

app-application-submitted {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  height: calc(100vh - 12px);
  margin-top: -20px;
}

.no-hover {
  transform: inherit !important;
  box-shadow: 0 1px 15px 0 rgb(34 41 47 / 15%) !important;
}

.accept-aggrement-page .form-group.checkbox-form {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

.accept-aggrement-page .form-group.checkbox-form span.text-decoration-underline {
  font-weight: 600;
}

.product-decription-page textarea {
  // resize: none;
  border-radius: 0 !important;
  //min-height: 130px;
}

.product-decription-page span.word-counter {
  top: 65%;
  bottom: inherit !important;
  right: 0 !important;
}

.success-card img {
  max-height: 80px;
}

// custom header
vertical-layout {
  transition: none !important;
}

vertical-layout.onboarding .app-content.content {
  margin-left: 0 !important;
  transition: 0ms ease all !important;
  background: #ffffff !important;
}

// vertical-layout.onboarding .header-navbar.floating-nav {
//     width: 100%;
//     max-width: 100%;
//     left: 0;
//     margin-top: 0;
// }

// for entity login task style fixes
.header-navbar {
  transition: none !important;
}

vertical-layout.onboarding app-navbar.header-navbar {
  border-radius: 0 !important;
}

vertical-layout.onboarding .header-navbar.floating-nav {
  left: 0 !important;
}

vertical-layout.onboarding .header-navbar.floating-nav {
  margin-top: 0;
  /* Fix for navbar issue for merchant*/
  width: 100% !important;
  margin: 0;
}

.content-wrapper.container-xxl.p-0 {
  max-width: 100%;
}

.menu-navs {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
}

.menu-navs.active {
  display: block !important;
  position: fixed;
  right: 0;
  background: #ffffff;
  top: 70px;
  width: 240px;
  padding: 0 !important;
  height: 100%;
  box-shadow: -4px 17px 19px #e1e1e1;
}

.menu-navs.active li {
  padding: 10px 10px 10px 10px !important;
}

.menu-navs.active li a {
  color: #625f6e;
}

.nav .dropdown.show .dropdown-toggle::after {
  background-image: url(../assets/images/drop-menu.svg);
}

.nav .dropdown-item:hover,
.nav .dropdown-item:focus {
  background: var(--primary-color);
  color: #000000;
}

.header-navbar-shadow {
  display: none;
}

.header-navbar .navbar-container ul.navbar-nav li i.ficon:hover,
.header-navbar .navbar-container ul.navbar-nav li svg.ficon:hover {
  color: #6e6b7b !important;
}

.payment-gateway-page .card.comman,
.comman-radio-card .form-check label {
  cursor: pointer;
}

// dashboard css
app-menu.vertical-menu a.navbar-brand h2.brand-text {
  display: none !important;
}

app-menu.vertical-menu .brand-logo img {
  max-width: 162px !important;
}

// app-menu.vertical-menu span.menu-title {
//     // color: #000;
//     color: var(--text-color) !important;
// }

.custom-error {
  font-weight: 500;
  font-size: 11px;
  display: flex;
  color: #ea5455;
  align-items: center;
}

.vertical-layout.vertical-menu-modern .main-menu .navigation li a:hover {
  background: #f5f5f5;
  border-radius: 5px;
}

.vertical-layout.vertical-menu-modern .main-menu .navigation li a:hover span {
  color: #000000 !important;
}

.vertical-layout.vertical-menu-modern .main-menu.menu-light .navigation>li.active>a {
  box-shadow: none;
  background: var(--primary-color) !important;
  color: var(--text-color) !important;
}

.vertical-layout.vertical-menu-modern .main-menu.menu-light .navigation>li.active>a span.menu-title {
  color: var(--text-color) !important;
}

.vertical-layout.vertical-menu-modern .navigation li {
  margin-bottom: 4px;
}

// dashboard css end
.navbar-floating .header-navbar-shadow {
  display: none !important;
}

.tradedcompany-page .other-form label,
.business-website-page .other-form label,
.verify-business-page .other-form label,
.bankruptcy-page .other-form label,
.general-info .click-form label,
.general-info.products-services .form-group.label-relative.w-100.mt-1.w-100 label,
.general-info.products-services .col-md-6.col-12.px-0 label,
.general-info.products-services .col-md-6.col-12.pl-md-1.pl-0.pr-md-1.pr-0 label {
  box-shadow: none !important;
}

.modal-header.mt-1 {
  margin-top: 0 !important;
}

.business-website-page input.form-control,
input#owner_email {
  text-transform: lowercase !important;
}

.business-website-page input#website {
  text-transform: none !important;
}

.star-img img {
  width: 18px;
  height: auto;
}

.star-absolute img {
  width: 26px;
}

.star-absolute {
  top: -13px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 26px;
}

.accept-aggrement-page p#contracts-container a {
  font-size: 1rem !important;
  color: var(--primary-color);
}

.accept-aggrement-page p#contracts-container input[type="checkbox"].ps-contract-target {
  margin: -1px 0px 0px -20px;
}

.accept-aggrement-page p#contracts-container input[type="checkbox"].ps-contract-target {
  display: none;
}

.accept-aggrement-page label.ps-contract-label:before {
  background: #ffffff;
  content: "";
  height: 16px;
  width: 16px;
  position: absolute;
  left: -26px;
  top: 2px;
  border-radius: 2px;
  border: 1px solid rgb(167, 167, 167);
}

.accept-aggrement-page input:checked+label:before {
  background: var(--primary-color) !important;
}

.accept-aggrement-page input:checked+label:after {
  left: -21px;
  top: 4px;
  width: 7px;
  height: 10px;
  transform: rotate(45deg);
  border-style: solid;
  border-color: white;
  -o-border-image: none;
  border-image: none;
  -o-border-image: initial;
  border-image: initial;
  border-width: 0px 2px 2px 0px;
  z-index: 1;
  content: "";
  position: absolute;
}

// custom header-end
.data-electrical .scard-wrap {
  display: flex;
}

.header-navbar {
  z-index: 100 !important;
}

.product-services-details .scard-wrap {
  margin-bottom: 12px;
}

// app-signup-offer-view .mxh-250 {
//     max-height: 170px;
// }
.accept-aggrement-page .ps-group.ps-style-checkbox .ps-contract:last-child {
  padding-bottom: 0 !important;
}

.accept-aggrement-page p#contracts-container {
  margin-bottom: 10px;
}

ul.comman-listing.font-roboto li {
  font-weight: 400 !important;
}

app-auth-signup .onboard-pages {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

vertical-layout.onboarding .app-content.content {
  height: 100%;
}

app-signup-offer-view .sidebar-offer .sidev1 {
  margin: 8px 0 6px 0 !important;
}

.offer_list .align-items-center {
  align-items: flex-start !important;
}

.bookmark-wrapper a.dropdown-item:hover {
  color: #000000 !important;
}

@media screen and (max-height: 700px) {
  .offers-outer-wrapper.mxh-250 {
    max-height: 130px !important;
    min-height: 90px;
  }

  .offers-outer-wrapper.mxh-405 {
    max-height: 285px;
  }
}

@media (min-width: 1700px) {
  .select-hardware-page {
    left: 0 !important;
  }

  .offer_content_web .ss img {
    bottom: -50px !important;
  }

  .new_offer_content_web .ss img {
    bottom: -50px !important;
  }
}

@media (min-width: 1280px) {
  .offers-outer-wrapper {
    // min-height: 225px;
    min-height: 185px;
  }

  .modal-open .modal {
    overflow: hidden !important;
  }
}

@media (max-width: 1440px) {
  ul.nav.navbar-nav.flex-row.justify-content-around li.nav-item {
    margin-right: 55px;
  }

  app-auth-signup .action-btn-row {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

// .form-control.password-control {
//   background-color: inherit !important;
//   background-image: url(assets/images/pages/key.png) !important;
//   background-position: 100% 11px;
//   background-repeat: no-repeat !important;
//   background-size:auto !important;
// }

/*
*  STYLE 2
*/

.custom-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--lightgrey);
}

.custom-scroll::-webkit-scrollbar {
  width: 7px;
  background-color: var(--lightgrey);
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--blue);
}

.custom-scroll {
  overflow-y: auto;
  scrollbar-color: var(--blue) var(--lightgrey);
  scrollbar-width: thin;
  border-radius: 10px !important;
}

.card .product-img {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card .product-img img {
  max-height: 200px;
}

.price {
  background: var(--green);
  // min-width: 90px;
  height: 30px;
  color: #fff;
  text-align: center;
  font-weight: 400;
  border-radius: 20px;
  line-height: normal;
  font-size: 0.85rem;
  right: 0;
  top: 12px;
  left: auto;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  font-family: "Roboto", sans-serif;
}

.price-month {
  position: absolute;
  right: 15px;
  top: 12px;
}

.price-month .plus1 {
  font-size: 26px;
  position: absolute;
  margin-top: -5px;
  text-align: center;
  margin-left: -6px;
}

.price-month .text-price {
  margin-top: 20px;
  font-size: 0.85rem !important;
  font-weight: 600 !important;
}

.add-access {
  min-height: 18px;
  cursor: pointer;
}

#category-search-list>.col-12 {
  padding: 0;
}

#category-search-list.list {
  height: auto !important;
  min-height: 10px;
  overflow: auto;
}

.accept-payment-page .card,
.comman-listing li {
  cursor: pointer;
}

.hardware-col .card-title h5 {
  padding-right: 90px;
}

.offer_content_web {
  box-shadow: none !important;
}

.new_offer_content_web {
  box-shadow: none !important;
}

.offer_content_web.show-full-hight {
  height: 100vh;
  min-height: 100vh;
}

.new_offer_content_web.show-full-hight {
  height: 100vh;
  min-height: 100vh;
}

#category-search-list .cp {
  border-bottom: 1px solid #f1f1f1;
}

#category-search-list .cp:last-child {
  border-bottom: none;
}

#category-search-list.list .search-result p {
  font-weight: 500 !important;
  font-size: 0.95rem !important;
}

#category-search-list {
  padding: 0 !important;
}

.select-hardware-page .page-area>.row,
.modal-content .overflow-auto.custom-scroll>.row {
  justify-content: center !important;
}

@media (max-width: 576px) {
  .container {
    max-width: 100%;
  }

  .dropdown-user .user-nav.d-sm-flex.d-none {
    display: block !important;
  }

  .header-navbar .navbar-container ul.navbar-nav li.dropdown-user a:last-child {
    padding-left: 5px;
  }

  .vertical-overlay-menu a.nav-link.menu-toggle {
    top: 5px !important;
  }

  .vertical-overlay-menu .navbar-floating .navbar-container:not(.main-menu-content) {
    padding-left: 0;
    padding-right: 0;
  }
}

.form {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 100vh;
  margin-bottom: 20px;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  margin-top: 10px;
}

.form::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  position: absolute;
  left: 20px;
  top: -7px;
  border-radius: 0 !important;
}

input[type="email"],
input[type="password"] {
  padding-right: 30px !important;
}

/* Hide the browser's default checkbox */

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border: 1px solid #a7a7a7;
  border-radius: 2px;
}

/* When the checkbox is checked, add a blue background */

input[type="checkbox"]:checked~.checkmark {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color) !important;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

input[type="checkbox"]:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.checkmark:after {
  left: 4px;
  top: 1px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-form label {
  padding-left: 28px;
  position: relative;

  @media only screen and (max-width: 480px) {
    padding-left: 20px;
  }
}

.btn.view-offer {
  padding: 10px 10px;
  font-size: 14px;
  display: none;
}

.offer-banner-modal {
  display: flex;
  position: fixed;
  z-index: 9999;
  padding: 20px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  left: 0;
  top: 0;
  outline: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--gtr-color-base-black-20);
}

.offer-banner-modal-header {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 10;
}

.offer-banner-modal-content {
  background-color: #fefefe;
  margin: auto;
  /* padding: 20px; */
  width: 90%;
  --gtr-background-transparent: var(--gtr-color-base-white-100);
  border-radius: var(--gtr-border-radius-6);
  -webkit-box-shadow: var(--gtr-content-box-shadow-base);
  box-shadow: var(--gtr-content-box-shadow-base);
  max-width: 90%;
  width: 400px;
}

.sidebar-offer {
  position: absolute;
  right: 0;
  top: 62px;
}

.sidebar-offer .mb-1,
.sidebar-offer .my-1 {
  margin-bottom: 0.5rem !important;
}

.new-sidebar-offer .mb-1,
.new-sidebar-offer .my-1 {
  margin-bottom: 0.5rem !important;
}

.sidebar-offer .mb-2,
.sidebar-offer .my-2 {
  margin-bottom: 1rem !important;
}

.new-sidebar-offer .mb-2,
.new-sidebar-offer .my-2 {
  margin-bottom: 1rem !important;
}

.sidebar-offer .mb-3,
.sidebar-offer .my-3 {
  margin-bottom: 1.5rem !important;
}

.new-sidebar-offer .mb-3,
.new-sidebar-offer .my-3 {
  margin-bottom: 1.5rem !important;
}

.sidebar-offer .mb-4,
.sidebar-offer .my-4 {
  margin-bottom: 2rem !important;
}

.new-sidebar-offer .mb-4,
.new-sidebar-offer .my-4 {
  margin-bottom: 2rem !important;
}

.sidebar-offer .mb-5,
.sidebar-offer .my-5 {
  margin-bottom: 2.5rem !important;
}

.new-sidebar-offer .mb-5,
.new-sidebar-offer .my-5 {
  margin-bottom: 2.5rem !important;
}

.sidebar-offer .mt-1,
.sidebar-offer .my-1 {
  margin-top: 0.5rem !important;
}

.new-sidebar-offer .mt-1,
.new-sidebar-offer .my-1 {
  margin-top: 0.5rem !important;
}

.sidebar-offer .mt-2,
.sidebar-offer .my-2 {
  margin-top: 1rem !important;
}

.new-sidebar-offer .mt-2,
.new-sidebar-offer .my-2 {
  margin-top: 1rem !important;
}

.sidebar-offer .mt-3,
.sidebar-offer .my-3 {
  margin-top: 1.5rem !important;
}

.new-sidebar-offer .mt-3,
.new-sidebar-offer .my-3 {
  margin-top: 1.5rem !important;
}

.sidebar-offer .mt-4,
.sidebar-offer .my-4 {
  margin-top: 2rem !important;
}

.new-sidebar-offer .mt-4,
.new-sidebar-offer .my-4 {
  margin-top: 2rem !important;
}

.sidebar-offer .mt-5,
.sidebar-offer .my-5 {
  margin-top: 2.5rem !important;
}

.new-sidebar-offer .mt-5,
.new-sidebar-offer .my-5 {
  margin-top: 2.5rem !important;
}

.sidebar-offer .pb-1,
.sidebar-offer .py-1 {
  padding-bottom: 0.5rem !important;
}

.new-sidebar-offer .pb-1,
.new-sidebar-offer .py-1 {
  padding-bottom: 0.5rem !important;
}

.sidebar-offer .pb-2,
.sidebar-offer .py-2 {
  padding-bottom: 1rem !important;
}

.new-sidebar-offer .pb-2,
.new-sidebar-offer .py-2 {
  padding-bottom: 1rem !important;
}

.sidebar-offer .pb-3,
.sidebar-offer .py-3 {
  padding-bottom: 1.5rem !important;
}

.new-sidebar-offer .pb-3,
.new-sidebar-offer .py-3 {
  padding-bottom: 1.5rem !important;
}

.sidebar-offer .pb-4,
.sidebar-offer .py-4 {
  padding-bottom: 2rem !important;
}

.new-sidebar-offer .pb-4,
.new-sidebar-offer .py-4 {
  padding-bottom: 2rem !important;
}

.sidebar-offer .pb-5,
.sidebar-offer .Py-5 {
  padding-bottom: 2.5rem !important;
}

.new-sidebar-offer .pb-5,
.new-sidebar-offer .Py-5 {
  padding-bottom: 2.5rem !important;
}

.sidebar-offer .pt-1,
.sidebar-offer .px-1 {
  padding-top: 0.5rem !important;
}

.new-sidebar-offer .pt-1,
.new-sidebar-offer .px-1 {
  padding-top: 0.5rem !important;
}

.sidebar-offer .pt-2,
.sidebar-offer .px-2 {
  padding-top: 1rem !important;
}

.new-sidebar-offer .pt-2,
.new-sidebar-offer .px-2 {
  padding-top: 1rem !important;
}

.sidebar-offer .pt-3,
.sidebar-offer .px-3 {
  padding-top: 1.5rem !important;
}

.new-sidebar-offer .pt-3,
.new-sidebar-offer .px-3 {
  padding-top: 1.5rem !important;
}


.sidebar-offer .pt-4,
.sidebar-offer .px-4 {
  padding-top: 2rem !important;
}

.new-sidebar-offer .pt-4,
.new-sidebar-offer .px-4 {
  padding-top: 2rem !important;
}

.sidebar-offer .pt-5,
.sidebar-offer .px-5 {
  padding-top: 2.5rem !important;
}

.new-sidebar-offer .pt-5,
.new-sidebar-offer .px-5 {
  padding-top: 2.5rem !important;
}

.back_button {
  min-width: 100px;
  padding: 9px 10px;
  border: 1px solid #c5c5c5;
  outline: none;
  background: #f1f1f1;
  color: var(--primary);
  border-radius: 5px;
  font-size: 0.9rem;
  box-shadow: none;
  text-transform: capitalize !important;
}

.shadow-1 {
  box-shadow: 4px 6px 9px rgba(0, 0, 0, 0.1);
}

.shadow-2 {
  box-shadow: 4px 6px 29px rgb(0 0 0 / 19%);
}

.of-scroll {
  overflow: scroll;
}

.of-hidden {
  overflow: hidden;
}

.ofx-scroll {
  overflow-x: scroll;
}

.ofx-hidden {
  overflow-x: hidden;
}

.ofy-scroll {
  overflow-y: scroll;
}

.ofy-hidden {
  overflow-y: hidden;
}

.form-group {
  position: relative;
  // margin-bottom: 1.8rem;
  margin-bottom: 1.4rem;
}

.form-control {
  padding: 10px 10px 2px 0px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #d8d8d8;
  background: transparent;
  border-radius: 0;
  box-shadow: none !important;
  font-size: 0.9rem;
  height: 2.5rem;
  font-weight: 600;
  color: var(--primary);
  position: relative;
  z-index: 2;
  background-color: transparent !important;
}

.form-control:focus {
  color: var(--primary);
}

select {
  border: none;
  outline: none;
  padding: 10px 0px;
  background: transparent;
  border-bottom: 1px solid gainsboro;
  color: #5e5873;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--primary);
}

option {
  color: #5e5873;
  font-size: 0.9rem;
}

input .phone_input {
  padding: 10px 10px 10px 140px;
}

input:focus {
  outline: none;
}

label {
  color: var(--default);
  font-weight: normal;
  position: absolute;
  left: 0px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  font-size: 0.9rem;
}

input:focus~label,
input:valid~label {
  top: -16px;
  // color: var(--primary);
}

.invalid-field {
  top: -10px;
  // color:rgb(241, 52, 52)
}

.bar {
  position: relative;
  display: block;
}

.phone_input {
  padding: 10px 10px 5px 145px;

  @media only screen and (max-width: 480px) {
    padding: 10px 10px 5px 100px;
  }

  @media only screen and (max-width: 376px) {
    padding: 10px 10px 5px 40px;
  }
}

.bar {
  position: relative;
  display: block;
}

.bar:before,
.bar:after {
  content: "";
  width: 0;
  bottom: 1px;
  position: absolute;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.highlight {
  position: absolute;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

input:focus~.highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* animations */

@-webkit-keyframes inputHighlighter {
  from {
    background: var(--primary);
  }

  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: var(--primary);
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: var(--primary);
  }

  to {
    width: 0;
    background: transparent;
  }
}

$labelClr: "#201f1f";

h1 .up {
  color: var(--primary);
}

.i_mail {
  position: absolute;
  right: 3px;
  bottom: 6px;
  font-size: 15px;
}

.label_phone {
  position: absolute;
  top: 12px;
  font-size: 0.857rem;
  left: 0px;
  z-index: 3;

  @media only screen and (max-width: 376px) {
    top: -14px;
  }
}

.country_code {
  position: absolute;
  top: 8px;
  font-size: 0.857rem;
  color: "#201f1f";
  left: 6rem;
  background: #eae9e9;
  width: 36px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 1rem;
  z-index: 3;

  @media only screen and (max-width: 480px) {
    left: 3.5rem
  }

  @media only screen and (max-width: 376px) {
    left: 0px;
  }
}

.terms_conditions {
  font-size: 0.857rem;
  color: var(--primary);
  cursor: pointer;
}

#terms {
  width: 18px;
  height: 18px;
  margin-left: 1px;
}

.offer_image_content_web {
  width: 100%;
  height: 80vh;
  position: absolute;
  border-radius: 12px;
  background: rgb(38, 95, 180);
  background: linear-gradient(180deg,
      rgba(38, 95, 180, 1) 0%,
      rgba(9, 64, 121, 0.9836134282814688) 0%,
      rgba(78, 174, 251, 1) 100%);
}

.offer_content_web {
  top: 0px;
  max-width: 285px;
  min-width: 285px;
  right: 0;
  height: calc(100vh - 170px);
  position: absolute;
  border-radius: 0;
  max-height: 770px;
  position: fixed;
}

.bg-f5 {
  background: #f5f5f5;
}

.table-ac-grey table {
  background: #f5f5f5;
}

.table-ac-grey table td {
  position: relative;
}

.table-ac-grey table th,
.table-ac-grey table thead {
  background: #f5f5f5 !important;
}

.table-ac-grey tfoot td {
  color: #6e6b7b !important;
  border: none;
  font-weight: 500 !important;
}

.table-ac-grey th,
.table-ac-grey td {
  padding: 0.62rem 1.5rem;
}

.table-ac-grey tr.border-row td:last-child,
.table-ac-grey tr.border-row td:nth-child(2) {
  border-top: 2px solid #cbc6c6;
}

.table-ac-grey tfoot tr:first-child td {
  border-top: 1px solid #bdb5b5 !important;
}

.table-ac-grey thead th:first-child,
.table-ac-grey tbody tr td:first-child {
  padding-left: 0;
}

.table-ac-grey tfoot tr:last-child td:last-child {
  font-weight: 500 !important;
}

.table-ac-grey ul li {
  color: #6e6b7b;
  padding: 3px 0;
}

.table-ac-grey ul {
  padding: 10px 0;
  width: 270px;
  float: right;
}

.table-ac-grey .add-col {
  vertical-align: top !important;
}

.li-border-line {
  border-top: 2px solid #c9c4c4;
}

.table-ac-grey ul li:last-child {
  color: var(--primary);
}

.table-ac-grey ul li span {
  min-width: 72px;
  display: inline-block;
  font-weight: 400;
}

.table-ac-grey ul {
  padding: 5px 0;
}

app-analytics .card-body a {
  font-size: 1rem !important;
}

.price-pay strong {
  background: #88c97d;
  color: #ffffff;
  padding: 6px 14px;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 400;
  margin-left: 10px;
  cursor: pointer;
}

.price-pay {
  position: relative;
  z-index: 22;
  top: -7px;
}

.price-pay {
  color: #88c97d;
}

.table-ac-grey .card .table th:last-child,
.table-ac-grey .card .table tr td:last-child {
  padding-right: 0;
}

.incl-btn {
  background: #32b3e6;
  color: #ffffff;
  padding: 2px 14px;
  border-radius: 10px;
  font-size: 12px;
  border: none;
  position: absolute;
  right: 0;
}

.vertical-menu-modern .header-navbar.floating-nav {
  margin-top: 0 !important;
}

@media (min-width: 1280px) {
  .space-pre {
    white-space: pre;
  }
}

@media (min-width: 1200px) {
  .offer_content_web {
    min-height: 720px;
  }
}

.offer_content_web h6,
.offer_content_web p {
  text-align: center;
}

.new_offer_content_web h6,
.new_offer_content_web p {
  text-align: center;
}

.offer_content_web img {
  position: absolute;
  bottom: 0;
  max-width: 318px;
  bottom: -132px;
  left: -2px;
  width: 102%;
  z-index: 1;
}

.new_offer_content_web img {
  position: absolute;
  bottom: 0;
  max-width: 318px;
  bottom: -132px;
  left: -2px;
  width: 102%;
  z-index: 1;
}

.offer_content_web .offer_list {
  height: auto;
  // background: rgb(10 50 88);
  background: rgb(0 0 0 / 30%);
  border-radius: 5px;
}

.new_offer_content_web .offer_list {
  height: auto;
  // background: rgb(10 50 88);
  background: rgb(0 0 0 / 30%);
  border-radius: 5px;
}

.offer_val_web {
  padding: 2px;
  border: 1px solid #fff;
  border-radius: 24px;
  min-width: 95px;
}

.plus_web {
  font-size: 1.5rem;
  margin: 0 10px 0 10px;
}

/* Container holding the image and the text */

.offer_image {
  position: relative;
  text-align: center;
  color: white;
  width: 100%;
  border-radius: 12px;
}

.offer_image_content {
  width: 100%;
  height: 115px;
  border-radius: 12px;
  background: rgb(38, 95, 180);
  background: linear-gradient(180deg,
      rgba(38, 95, 180, 1) 0%,
      rgba(9, 64, 121, 0.9836134282814688) 0%,
      rgba(78, 174, 251, 1) 100%);
}

.offer_content {
  position: absolute;
  top: 0;
  width: 100%;

  h6,
  p {
    text-align: start;
    padding-left: 16px;
  }

  p {
    margin: 0;
    font-size: 9px;
  }

  .offer_list_mobile {
    // display: block;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3px 15px;
    width: 100%;

    .list_scroll_mobile {
      // display: inline-block;
      width: 248px;
      height: 32px;
      /* UI Properties */
      background: var(--black) 0% 0% no-repeat padding-box;
      background: #000000 0% 0% no-repeat padding-box;
      border-radius: 16px;
      opacity: 0.16;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.offer_val {
  padding: 5px;
  border: 1px solid #fff;
  border-radius: 24px;
  width: 80px;
}

.plus {
  font-size: 2rem;
}

.text-deco {
  text-decoration: underline !important;
}

.opac {
  opacity: 0.5;
}

// .was-validated .form-control:invalid,
// .form-control.is-invalid {
//   background-image: none !important;
// }
.sidebar-offer .col-12 {
  position: relative;
  z-index: 2;
}

.main.authentication-screens {
  padding-bottom: 60px;
}

.action-btn-row {
  margin-top: 35px;
  margin-bottom: 25px;
}

.progress-row .text-center.pt-1 {
  padding: 0 !important;
  color: var(--primary);
  font-size: 0.7rem !important;
}

.plus {
  font-size: 1.5rem;
}

.text-deco {
  text-decoration: underline !important;
}

.opac {
  opacity: 0.5;
}

.cp {
  cursor: pointer;
}

p {
  // font-size: 0.9rem !important;
  font-size: 1rem !important;
}

.cate-listing {
  background: #ffffff;
  box-shadow: 2px 2px 20px #e3e3e3;
  padding: 20px;
}

.cate-listing li {
  padding: 8px 20px;
  border-bottom: 1px solid #e9e9e9;
  color: var(--primary);
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #e7e3e3;
  background: whitesmoke;
}

.cate-listing li:nth-child(even) {
  background: white;
}

.cate-listing li.active {
  border: 1px solid var(--blue);
}

.card.comman {
  padding: 0 15px 20px 15px;
  min-height: 180px;
  display: flex;
  justify-content: center;
}

.shopping-plugin-page .card.comman {
  cursor: pointer;
}

.card:hover,
.comman-listing li:hover,
.comman-radio-card label:hover {
  transform: translate(0px, -3px);
  -webkit-transform: translate(0px, -3px);
  -moz-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  box-shadow: 0 15px 17px rgb(34 41 47 / 11%);
}

.card.active,
.comman-listing li.active {
  border: 1px solid var(--blue) !important;
  box-shadow: 0 15px 17px rgb(34 41 47 / 11%);
}

.busi-col:last-child img {
  margin-top: 10px;
}

.number-counter span {
  cursor: pointer;
}

.number-counter .minus,
.number-counter .plus {
  width: 36px;
  height: 28px;
  background: #e3e3e3;
  border-radius: 4px;
  padding: 2px;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 24px;
  color: var(--primary);
}

.number-counter input {
  height: 28px;
  width: 40px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 0px;
  display: inline-block;
  vertical-align: middle;
  border-left: none;
}

.number-counter span.minus {
  border-radius: 4px 0 0 4px;
  border-right: 0;
  font-weight: 800;
}

.number-counter span.plus {
  border-radius: 0px 4px 4px 0px;
  border-left: 0;
}

.number-counter span.minus strong {
  transform: rotate(90deg) !important;
  width: 30px;
  display: block;
  line-height: 23px;
}

// .card.comman:hover,
// .card.comman:focus,
.card.comman.active {
  border: 1px solid var(--blue) !important;
  box-shadow: 0 15px 17px rgb(34 41 47 / 11%);
}

.card:hover,
.card.comman:hover .comman-listing li {
  border-color: transparent !important;
}

.comman-radio-card .form-check input:checked[type="radio"]+label {
  border: 1px solid var(--blue) !important;
}

.radio-input label.form-check-label:before {
  height: 16px;
  width: 16px;
  background: #ffffff;
  content: "";
  position: absolute;
  left: -22px;
  border: 1px solid #b2b2b2;
  border-radius: 100%;
  top: 2px;
}

.radio-input label.form-check-label:after {
  height: 8px;
  width: 8px;
  background: #ffffff;
  content: "";
  position: absolute;
  left: -18px;
  border-radius: 100%;
  top: 6px;
}

.radio-input input:checked+label:after {
  height: 8px;
  width: 8px;
  background: var(--blue);
  content: "";
  position: absolute;
  left: -18px;
  border-radius: 100%;
  top: 6px;
}

.radio-input input {
  display: none;
}

.sidebar-offer .mobile-content {
  display: none;
}

.new-sidebar-offer .mobile-content {
  display: none;
}

div#category-search-list {
  max-width: 704px;
}

footer.footer {
  display: none;
}

.payment-percentage-page .color-badge {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  flex: auto !important;
  width: 20px;
}

.payment-percentage-page .ngx-slider {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.payment-percentage-page .ngx-slider-bar {
  height: 6px !important;
  border-radius: 3px !important;
}

.payment-percentage-page .ngx-slider .ngx-slider-pointer {
  width: 19px;
  height: 19px;
  top: -8px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 33%);
  background-color: #ffffff;
  z-index: 3;
  border-radius: 16px;
  outline: none;
}

.payment-percentage-page .ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.payment-percentage-page .ngx-slider .ngx-slider-selection {
  background: var(--phone) !important;
}

.payment-percentage-page .ngx-slider .ngx-slider-span.ngx-slider-combined {
  opacity: 0 !important;
}

.payment-percentage-page .ngx-slider .ngx-slider-pointer-max {
  right: 0;
}

.payment-percentage-page .ngx-slider .ngx-slider-pointer-min {
  left: 0;
}


// .payment-percentage-page  .ngx-slider .ngx-slider-bar {background: #31B519;}
.payment-percentage-page .ngx-slider .ngx-slider-bar-wrapper.ngx-slider-left-out-selection .ngx-slider-bar {
  // background: #54b51d !important;
  background: var(--in-person) !important;
}

.ngx-slider .ngx-slider-bar-wrapper.ngx-slider-right-out-selection .ngx-slider-bar {
  // background: #f5bd3a !important;
  background: var(--online) !important;
}

.two_select {

  // .payment-percentage-page .ngx-slider .ngx-slider-pointer:after{display: none;}
  .ngx-slider-bar {
    visibility: visible !important;
    background: var(--online) !important;
  }

  .ngx-slider-span .ngx-slider-bar-wrapper .ngx-slider-selection-bar {
    visibility: visible !important;
  }

  .ngx-slider-selection {
    background: var(--phone) !important;
    visibility: initial !important;
  }

  // ngx-slider-span ngx-slider-bar ngx-slider-selection
}

.onboard-pages .validation_error,
.validation_error {
  position: relative;
  top: inherit;
  margin-bottom: 0;
  margin-top: 5px;
  color: rgb(241, 52, 52);
  font-size: 0.757rem;
}

.general-info .form-control {
  padding-top: 0;
}

.d-block.page-area-title {
  margin-top: 10px;
}

.page-area {
  margin-top: 40px !important;
}

.business-principles .page-area {
  margin-top: 50px !important;
}

.select-hardware-page .page-area {
  margin-top: 20px !important;
}

.busienss-categoty-page .page-area {
  margin-top: 60px !important;
}

.offer_content_web.show-offer-img:after {
  background: var(--sidebar-image-url);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 185px;
  content: "";
  left: 0px;
  bottom: -13px;
  position: absolute;
  z-index: 1;
  right: 0;
  margin: 0 auto;
  // background-size: 100%;
}

.new_offer_content_web.show-offer-img:after {
  background: var(--sidebar-image-url);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 185px;
  content: "";
  left: 0px;
  bottom: -13px;
  position: absolute;
  z-index: 1;
  right: 0;
  margin: 0 auto;
  // background-size: 100%;
}

.offer_content_web:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgb(0 0 0 / 51%));
  top: 0;
}

.new_offer_content_web:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgb(0 0 0 / 51%));
  top: 0;
}

.offer_content_web .ss img {
  max-width: 100%;
  width: 50px;
  height: auto;
  position: relative;
  // bottom: -50px;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
}

.new_offer_content_web .ss img {
  max-width: 100%;
  width: 50px;
  height: auto;
  position: relative;
  // bottom: -50px;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
}

.offer_content_web .ss {
  position: absolute;
  width: 285px;
  height: 285px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 100%;
  border: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -50px;
  display: none !important;
}

.new_offer_content_web .ss {
  position: absolute;
  width: 285px;
  height: 285px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 100%;
  border: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -50px;
  display: none !important;
}


.total-offer hr {
  opacity: 0.2;
}

.total-offer .terminal-cost-row.px-1 {
  padding-top: 7px !important;
}

.total-offer .px-1.pt-1.monthly-cost-row {
  padding-top: 4px !important;
  padding-bottom: 0px;
}

.total-offer .mwx-100 {
  min-width: 96px;
}

.onboard-logout svg {
  top: -1px;
  position: relative;
  right: 5px;
}

.onboard-logout a:hover {
  color: var(--primary) !important;
}

.onboard-logout {
  position: absolute;
  right: 6%;
}

table {
  td {
    a {
      color: unset;
    }

    a:hover {
      color: unset;
    }
  }
}

.product-decription-page textarea {
  padding: 0 !important;
  min-height: 20px !important;
}

@media (max-width: 1850px) {
  .onboard-logout {
    right: 200px;
  }
}

@media (max-width: 1650px) {
  .onboard-logout {
    right: 270px;
  }
}

@media (max-width: 1530px) {
  .onboard-logout {
    right: 350px;
  }
}

@media (max-width: 1439px) {
  .onboard-logout {
    right: 230px;
  }
}

@media (max-width: 1300px) {
  .onboard-logout {
    right: 130px;
  }
}

@media (max-width: 1300px) {
  .onboard-logout {
    right: 135px;
  }
}

@media (max-width: 767px) {

  .table-ac-grey .table th,
  .table-ac-grey .table td {
    min-width: 200px;
  }

  .table-responsive.table-ac-grey.bg-f5.px-3 {
    padding: 0 !important;
  }

  .table-ac-grey thead th:first-child,
  .table-ac-grey tbody tr td:first-child {
    padding-left: 15px;
  }

  .onboard-logout svg {
    left: 9px;
  }

  .onboard-logout {
    // right: 105px;
    right: 10px;
  }

  .onboard-logout span {
    display: none;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1301px) {
  .select-hardware-page.select-hard {
    margin-left: -55px;
  }
}

@media only screen and (max-width: 1700px) and (min-width: 1441px) {
  .summary-page {
    margin-left: -80px;
  }
}

@media (max-width: 1440px) {
  .offer_content_web.show-offer-img:after {
    bottom: -37px;
  }

  .new_offer_content_web.show-offer-img:after {
    bottom: -37px;
  }

  .fs-22 {
    font-size: 1rem !important;
  }

  h1,
  .h1 {
    font-size: 1.7rem;
  }

  h3,
  .h3 {
    font-size: 1.3rem;
  }

  .offer_content_web {
    height: calc(100vh - 200px);
    max-width: 245px;
    min-width: 245px;
  }

  .offer_val_web {
    min-width: 75px;
  }

  .sidebar-offer .fs-14 {
    font-size: 0.8rem !important;
  }

  .new-sidebar-offer .fs-14 {
    font-size: 0.8rem !important;
  }


  .offer_content_web img {
    left: -3px;
  }

  .new_offer_content_web img {
    left: -3px;
  }

  .total-offer .mwx-100 {
    min-width: 70px;
  }
}

@media (max-width: 1399px) {
  .summary-conttent.mh-440 {
    max-height: 300px;
  }

  .summary-conttent.mh-450 {
    max-height: 300px;
  }
}

@media (max-width: 1300px) {
  .offer_content_web {
    height: calc(100vh - 60px);
    border-radius: 0;
    max-height: 100%;
    max-width: 280px;
    min-width: 280px;
  }

  .offer_content_web.show-offer-img:after,
  .offer_content_web .ss {
    display: none;
  }

  .btn.view-offer {
    display: block !important;
  }

  .offer_content_web {
    top: 60px;
    right: -290px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
  }

  .viewsdofr .offer_content_web {
    right: 0;
  }

  .main.authentication-screens {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .main>.container {
    order: 2;
  }

  div#sd-ofr {
    z-index: 1 !important;
  }

  .horizontal-layout .header-navbar {
    z-index: 10000;
  }
}

@media (max-width: 1201px) {

  .header-navbar.fixed-top,
  .header-navbar.floating-nav {
    left: 260px;
  }
}

@media (max-width: 1199px) {
  .vertical-overlay-menu .header-navbar.floating-nav {
    margin-top: 0px;
  }

  .vertical-overlay-menu .header-navbar.fixed-top,
  .header-navbar.floating-nav {
    left: 0;
  }

  .busienss-categoty-page .page-area {
    margin-top: 50px !important;
  }

  .business-principles .page-area {
    margin-top: 30px !important;
  }

  .custom-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  .custom-scroll::-webkit-scrollbar {
    width: 5px;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  .main.authentication-screens .container {
    order: 2;
  }

  .progress-row ul.nav.navbar-nav {
    left: 15px;
    top: 5px;
  }

  // custom nav
  vertical-layout.onboarding .menu-navs {
    display: none;
  }

  .menu-navs {
    display: block !important;
    position: fixed;
    right: 0;
    background: #ffffff;
    top: 70px;
    width: 240px;
    padding: 0 !important;
    height: 100%;
    box-shadow: -4px 17px 19px #e1e1e1;
    right: -250px;
  }

  section.main {
    position: relative;
    z-index: 1;
  }

  //  custom nav end

  .vertical-overlay-menu .navbar-floating .navbar-container:not(.main-menu-content) {
    min-height: inherit !important;
  }

  .vertical-overlay-menu ul.nav.navbar-nav {
    position: static !important;
    top: 0 !important;
  }

  .vertical-overlay-menu a.nav-link.menu-toggle {
    position: absolute !important;
    top: 7px !important;
  }
}

@media (max-width: 991px) {

  // .sidebar-offer {
  //     max-width: 600px;
  // }
  .summary-conttent.mh-440,
  .select-hardware-page .mh-400 {
    max-height: 100%;
  }

  .summary-conttent.mh-450,
  .select-hardware-page .mh-400 {
    max-height: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) and (orientation: landscape) {
  .offer_content_web .mxh-250 {
    max-height: 110px;
  }

  .new_offer_content_web .mxh-250 {
    max-height: 110px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  app-application-submitted {
    height: auto;
    margin-top: 40px !important;
    margin-bottom: 20px !important;
  }

  app-application-submitted button#submit {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {

  h1,
  .h1 {
    font-size: 1.3rem;
  }

  .fs-14 {
    font-size: 0.75rem !important;
  }

  .main.authentication-screens .mt-4 {
    margin-top: 20px !important;
  }

  .main.authentication-screens form.form {
    margin-top: 30px !important;
  }

  // .main.authentication-screens {
  //     padding-bottom: 30px;
  //     padding-top: 15px;
  // }

  .main.comman-main {
    padding-bottom: 30px;
    padding-top: 0px;
    margin-top: -15px;
  }

  .i_mail img {
    max-width: 22px;
  }

  // .offer_val_web {
  //     min-width: 50px;
  //     font-size: 0.7rem !important;
  // }
  // .authentication-screens h1 {
  //   text-align: center !important;
  // }
  .mt-3.progress-row {
    margin-top: 26px !important;
  }

  .busienss-categoty-page .ofy-scroll.list {
    margin: 0;
    margin-right: 0;
  }

  .progr-div {
    margin-top: 15px !important;
  }

  .mh-400,
  .mh-240 {
    min-height: 10px;
  }

  .sales-volume-page .form-group,
  .label-relative {
    display: flex;
    flex-direction: column;
  }

  .sales-volume-page .form-control,
  .label-relative .form-control {
    order: 2;
  }

  .sales-volume-page .form-group label,
  .label-relative label {
    position: relative;
    order: 1;
    top: 0;
  }

  .horizontal-menu .navbar.header-navbar .navbar-container {
    padding: 0.8rem 15px 0.8rem 3px !important;
  }

  .d-block p br {
    display: none;
  }

  .horizontal-layout .header-navbar .navbar-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .main.comman-main .form-control,
  .main.comman-main label {
    font-size: 1rem;
  }

  // .sidebar-offer {
  //     max-width: 576px;
  // }
  .ecomp-col .card.comman {
    min-height: 110px;
  }

  .payment-gateway-page .card.comman {
    min-height: 150px;
  }

  .ecomp-col .card.comman img {
    max-height: 70px;
  }

  .header-navbar {
    box-shadow: 0px 2px 20px rgb(0 0 0 / 11%);
  }

  .d-block.page-area-title {
    margin-top: 15px;
  }

  .mb-3.mt-3.progr-div {
    margin-bottom: 20px !important;
  }

  .page-area {
    margin-top: 20px !important;
  }

  .main .page-area {
    margin-top: 10px !important;
  }

  .busienss-categoty-page .page-area,
  .business-principles .page-area,
  .accept-payment-page .page-area,
  .summary-page .page-area {
    margin-top: 35px !important;
  }

  .yourbusiness-page .page-area {
    margin-top: 45px !important;
  }

  .start-business-page form.mt-3 {
    margin-top: 0 !important;
  }

  .settlement-page h1.create_account_heading {
    margin-bottom: 10px !important;
  }

  .product-received-page .d-flex.flex-row.text-center button.back_button,
  .business-type-page .d-flex.flex-row.text-center button.back_button {
    margin-right: 0 !important;
  }

  .payment-percentage-page {
    margin-top: 15px;
  }

  .small-labels.onboard-pages.sales-volume-page input:focus~label,
  .general-info.products-services input:focus~label,
  .small-labels.onboard-pages.sales-volume-page input:focus~label,
  .general-info.products-services input:valid~label,
  .sales-volume-page input:valid~label {
    top: 0px !important;
  }

  .general-info.products-services .small-labels input {
    padding: 10px 10px 2px 0px;
  }

  .general-info.products-services .small-labels input:focus~label,
  .general-info.products-services .small-labels input:valid~label {
    top: -16px !important;
  }

  .summary-page .checkmark {
    top: 0px;
  }

  .summary-page .sum-card h6 {
    position: relative;
    padding-right: 15px;
  }

  .summary-page .sum-card .cursor-pointer {
    position: absolute;
    top: 0;
    right: 0px;
  }

  div#category-search-list {
    margin-left: 15px !important;
    margin-right: 15px !important;
    width: auto;
  }

  .small-labels.onboard-pages .checkbox-form .checkmark {
    top: 1px;
  }

  //    .yourbusiness-page .multiselect-dropdown  {
  //         margin-bottom: 30px;
  //         margin-top: -14px;
  //     }
  app-signup-offer-view {
    margin-top: 0px;
  }

  .busienss-categoty-page button.back_button,
  .busienss-categoty-page .btn-primary.waves-effect {
    margin-top: 30px !important;
  }

  .product-img-1 {
    min-height: 105px;
  }

  // floating select label css
  // floating select label css end
  .start-business-page .page-area {
    margin-top: 30px !important;
  }

  img.success-tick {
    max-height: 60px;
  }

  .accept-aggrement-page .form-group.checkbox-form label {
    padding-left: 20px !important;
  }

  .accept-aggrement-page .form-group.checkbox-form .checkmark {
    top: 0px;
  }

  app-application-submitted {
    margin-top: 0;
    height: 88vh;
  }

  .success-card img {
    max-height: 60px;
  }

  app-application-submitted h3 {
    font-size: 1.1rem;
  }

  .data-electrical .scard-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .btn.view-offer {
    padding: 7px 7px;
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .offer_content_web .mxh-250 {
    max-height: 300px;
  }

  .new_offer_content_web .mxh-250 {
    max-height: 300px;
  }

  vertical-layout.onboarding app-navbar.header-navbar {
    margin: 0;
    width: 100% !important;
    border-radius: 0 !important;
  }

  .vertical-overlay-menu .position-relative.container-fluid.dashlink {
    padding: 0 !important;
  }
}

@media (max-width: 360px) {
  .btn.view-offer {
    padding: 7px 6px;
    font-size: 12px;
    margin-left: auto;
  }

  .bookmark-wrapper .nav li img {
    max-width: 166px;
  }

  .vertical-overlay-menu .dropdown-user a span,
  .vertical-overlay-menu .header-navbar .navbar-container ul.navbar-nav li.dropdown-user a:last-child {
    font-size: 10px !important;
  }

  .vertical-overlay-menu .dropdown-user a .avatar {
    width: 20px;
    height: 20px;
  }

  .vertical-overlay-menu .header-navbar .navbar-container ul.navbar-nav li.dropdown-user a:last-child svg {
    margin-right: 0 !important;
  }

  .vertical-overlay-menu .dropdown-user span.avatar svg {
    width: 10px !important;
  }
}

.multiselect-dropdown .dropdown-btn {
  padding: 10px 10px 2px 0px;
  display: block;
  width: 100%;
  border: none !important;
  border-bottom: 1px solid #d8d8d8 !important;
  background: transparent;
  border-radius: 0;
  box-shadow: none !important;
  font-size: 0.9rem;
  height: 2.5rem;
  font-weight: 600;
  color: #000000;
  color: var(--primary);
  position: relative;
  z-index: 2;
  background-color: transparent !important;
}

.multiselect-dropdown .selected-item {
  max-width: none !important;
  background: #fff !important;
  border: none !important;
  color: #000000 !important;
}

.multiselect-item-checkbox input[type="checkbox"]+div:before {
  display: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #000000 !important;
}

.remember_me label,
.forgot_password {
  color: #000 !important;
  font-weight: 500 !important;

  @media only screen and (max-width: 480px) {
    font-size: 0.83rem;
  }
}

.card {
  box-shadow: 0 1px 15px 0 rgb(34 41 47 / 15%);
  transition: all 0.2s ease-in-out;
  // for entity login task style fixes
  // background: var(--card-background);
  // &*{
  //   background: var(--card-background) !important;
  // }
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.4rem;
  border-bottom: 1px solid #ebe9f1;
  border-top-left-radius: calc(0.358rem - 1px);
  border-top-right-radius: calc(0.358rem - 1px);
  font-size: 40px;
  color: #000000;
}

.modal-header h5 {
  color: #000000;
}

.modal .modal-header .close {
  position: absolute !important;
  right: 27px;
}

app-signup-offer-view {
  position: relative;
  z-index: 1050;
}

app-e-commerce-platform {
  width: 100% !important;
}

.checkbox-form label {
  cursor: pointer;
}

.multiselect-dropdown .dropdown-btn .selected-item:hover {
  box-shadow: none;
}

.driver-state-dropdown-btn span.dropdown-btn:before {
  position: absolute;
  top: 7px;
  content: "Driver License State";
  left: 0;
  transition: all 0.2s ease;
}

.driver-state-dropdown-btn ng-multiselect-dropdown.active span.dropdown-btn:before {
  top: -16px;
}

.driver-state-dropdown-btn span.selected-item a {
  display: none;
}

.driver-state-dropdown-btn span.selected-item {
  padding-top: 7px !important;
}

.driver-state-dropdown-btn span.ng-star-inserted,
.comman-dropdown span.ng-star-inserted {
  display: none !important;
}

.driver-state-dropdown-btn span.selected-item-container.ng-star-inserted,
.comman-dropdown span.selected-item-container.ng-star-inserted {
  display: block !important;
}

.btn-principal-xs-style {
  display: flex;
}

.wordbreak {
  word-break: break-all;
}

.form-control,
.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500;
}

ng-multiselect-dropdown:focus span.dropdown-btn {
  border-bottom: solid 1px #6610f2 !important;
}

ng-multiselect-dropdown li.no-filtered-data h5 {
  font-size: 15px;
  margin-top: 7px;
  margin-left: 27px;
}

.nav.navbar-nav li.cp img,
span.brand-logo img {
  // max-height: 40px;
  max-height: 45px;
  max-width: 200px;
  // width: auto;
  object-fit: contain;
}

core-sidebar app-menu.vertical-menu .navbar-header {
  padding-left: 16px !important;
}

.price-bottom-row .bottom-cost {
  white-space: nowrap;
}

.price-bottom-row.px-1 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.vertical-overlay-menu ul.nav.navbar-nav {
  position: absolute;
  top: 7px;
}

.prospects-filter-wrapper::-webkit-scrollbar,
.ps-filter::-webkit-scrollbar,
.deposits-filter-wrapper::-webkit-scrollbar {
  display: none !important;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
  background: var(--background-transparent) !important;
}

// golbal feild set css
fieldset {
  display: block;
  margin-top: 10px;
  margin-bottom: 22px;
  border: 1px solid var(--gray-shades-10);
  padding-block-start: 22px;
  padding-inline-end: 22px;
  padding-block-end: 22px;
  padding-inline-start: 28px;
  min-inline-size: min-content;
  border-radius: var(--border-radius);
  position: relative;

  @media screen and (max-width: 640px) {
    padding: 22px 16px 10px 16px;
  }
}

legend {
  color: var(--gray-shades-60);
  background: var(--background);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  width: auto;
  top: -10px;
  left: 16px;
  padding: 0px 10px;

  @media screen and (max-width: 475px) {
    padding: 0px 5px;
    left: 10px;
  }
}

app-add-pricing,
app-other-agent-fees,
app-other-standard-fees {
  width: 70%;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
}

//  agent offer-bar
.offer_content_web {
  .offers-outer-wrapper {
    .offer_list .align-items-center {
      align-items: center !important;
    }

    span.text-left {
      text-align: start !important;
    }

    span.text-right {
      text-align: end !important;
    }
  }
}

//  agent offer-bar
.new_offer_content_web {
  .offers-outer-wrapper {
    .offer_list .align-items-center {
      align-items: center !important;
    }

    span.text-left {
      text-align: start !important;
    }

    span.text-right {
      text-align: end !important;
    }
  }
}

.agent-offer-sidebar {
  height: 100% !important;

  app-offer-banner,
  app-signup-offer-view {
    position: absolute !important;
    z-index: 2 !important;
    height: 100%;
  }

  .sidebar-offer .sidev1 {
    margin: 8px 0 6px 0 !important;
  }

  .new-sidebar-offer .sidev1 {
    margin: 8px 0 6px 0 !important;
  }

  .sidebar-offer {
    height: 100%;
    top: 0 !important;
  }

  .sidebar-offer .col-12 {
    position: relative;
    z-index: 1 !important;
  }

  position: relative !important;

  .offer_content_web {
    width: clamp(288px, 30%, 288px);
    margin-right: auto;
    position: absolute !important;
    right: 0px;
    height: 100vh;
    border-radius: var(--border-radius);
    transition: 0.3s ease;
    height: calc(100% - 41px);
    max-height: none !important;

    &::before {
      border-radius: var(--border-radius);
    }

    .interchange-offer-visa {
      span.offer_val_web {
        min-width: 62px !important;
      }

      .plus_web {
        font-size: 1rem;
        margin: 0 5px 0 5px;
      }
    }

    span.offer_val_web {
      font-size: 1.1rem !important;
      min-width: 80px;
      padding: 2px 10px !important;
    }

    .key-list {
      .all-fees-container {
        width: 100%;
        max-height: 300px;
        min-height: 0px;
        overflow-y: auto;
        // @media screen and (max-width: 768px) {
        //   min-height: 0px !important;
        //   max-height: 450px !important;
        // }
      }

      .other-agent-fees-type {
        width: 100% !important;
        text-align: start !important;

        span {
          color: #fff;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .offers-outer-wrapper {
        width: 100% !important;
        min-height: 0px !important;
        max-height: 190px !important;

        // @media screen and (max-width: 768px) {
        //   min-height: 0px !important;
        //   max-height: 250px !important;
        // }
        .offer_list .align-items-center {
          align-items: center !important;
        }

        span.text-left {
          text-align: start !important;
        }

        span.list-key-value {
          text-align: end !important;
        }
      }
    }

    &.show-offer-img:after {
      background: var(--sidebar-image-url);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 100%;
      height: 185px;
      content: "";
      left: 0px;
      bottom: -40px;
      position: absolute;
      z-index: 1;
      z-index: 1;
      right: 0;
      margin: 0 auto;
      background-size: 100%;
    }

    @media screen and (min-width: 1200px) {
      top: 0 !important;
      height: clamp(100%, 100%, 100%);
    }
  }

  @media screen and (min-width: 1300px) {
    .offer_content_web {
      top: 0 !important;
      //   height: 100% !important;
    }

    .offer_content_web.show-offer-img:after,
    .offer_content_web .ss {
      display: block !important;
    }

    .offer_content_web .ss {
      display: none !important;
    }
  }

  @media screen and (max-width: 992px) {
    .offers-outer-wrapper.mxh-405 {
      max-height: 300px !important;
    }

    .offer_content_web .ss {
      display: none !important;
    }
  }

  @media screen and (max-width: 768px) {
    .offer_content_web .ss {
      display: none !important;
    }
  }

  .offers-outer-wrapper {
    .offer_list {
      span.text-left:first-child {
        text-align: start !important;
      }

      span.text-left:nth-child(2) {
        text-align: start !important;
        margin-left: 10px;
      }
    }
  }
}

.iso-popover-content {
  ul {
    li {
      color: var(--gray-shades-80, rgba(0, 0, 0, 0.8));
      text-align: right;
      font-size: 13px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: start;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0px;
      }

      padding: 5px 10px;

      &:hover {
        background-color: rgba(0, 89, 156, 0.05);
      }
    }
  }
}

.iso-filter.mt-26 {
  margin-top: 26px;
}

.gtr-table-icons-size {
  font-size: 18px;
  line-height: 26px;
}

.iso-agent-title {
  color: var(--gray-shades-60, rgba(0, 0, 0, 0.6));
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
}


.iso-agent-main-container {
  .iso-agent-title {
    color: var(--gray-shades-60, rgba(0, 0, 0, 0.6));
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .mt-8 {
    margin-top: 8px;
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .mt-16 {
    margin-top: 16px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .mt-21 {
    margin-top: 21px;
  }

  .mb-21 {
    margin-bottom: 21px;
  }

  .p-14 {
    padding: 14px;
  }

  .mt-24 {
    margin-top: 24px;
  }

  .mt-26 {
    margin-top: 26px;
  }

  .mb-26 {
    margin-bottom: 26px;
  }

  .mb-32 {
    margin-bottom: 26px;
  }

  .mt-36 {
    margin-top: 36px;
  }

  .mb-36 {
    margin-bottom: 36px;
  }

  .mr-7 {
    margin-right: 7px;
  }
}



// for entity login task style fixes

// app-navbar.header-navbar.floating-nav {
//   width: auto !important;
// }
// app-navbar ul li a{
//   text-decoration: none !important;
// }
// app-navbar.margin-0{
//   margin: 0 !important;
// }
@media only screen and (max-width: 1200px) {
  app-navbar .bookmark-wrapper ul.menu-navs {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
}

// .vertical-layout.vertical-menu-modern .main-menu.menu-light .navigation > li.active > a span.menu-title{
//   text-decoration: none !important;
// }
// .vertical-layout.vertical-menu-modern .navigation li a{
//   text-decoration: none !important;
// }

// for entity login task style fixes ends ...
.gtr-custom-label {
  margin: 0px;
  display: block;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.6);
  position: unset;
}

.gtr-error-message {
  margin: 5px 0px;
  display: block;
  font-size: 11px;
  font-weight: 500;
  color: #ea5455;
}

.gtr-info-message {
  margin: 4px 0px;
  display: block;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  color: #f2994a;
}

.gtr-modal-close {
  border: none;
  background: none;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0;
}

.gtr-modal-close-link {
  line-height: 18px;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.43px;
  cursor: pointer;
  padding: 8px 20px;
  text-decoration: none;
}

// clickable actions in the tables
.clickable {
  gtr-tooltip {

    svg,
    gtr-icon {
      cursor: pointer;
    }
  }
}

.ps-page-title-breadcrum {
  color: var(--gray-shades-60, rgba(0, 0, 0, 0.6)) !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;

  &.main-page {
    cursor: pointer;
  }

  &.action {
    margin-left: 5px;
  }
}

app-business-principles {
  input.form-control.picker {
    overflow: visible;
  }

  input.pesn-none,
  .pesn-none-checkbox-label {
    pointer-events: none !important;
    opacity: 0.5 !important;
  }

  input:disabled~label,
  input:focus~label {
    top: -16px !important;
  }

  .bsp-cell-phone {
    position: relative;
    top: -85%;
  }

  .bsp-owner-percentage.form-group {
    margin-bottom: 0.8rem !important;
  }
}

app-summary {
  @media screen and (max-width: 768px) {
    .bsp-isprimary-contact {
      text-align: start !important;
    }
  }
}

.mms-form-edit-icon-wrapper {
  position: relative;
  margin-block: 1rem;

  span.detail-ps-edit-icon {
    position: absolute;
    top: 5px;
    right: 0px;
    background-color: #e6f1f9;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 11;

    gtr-icon {
      margin: 7px;
      color: #00599c;
      cursor: pointer;
    }
  }

  .mb-info-container {
    margin-top: 1rem;
  }
}

app-merchant-business-info {
  input[type="password"] {
    background: transparent !important;
    background-color: transparent !important;
  }
}

.gtr-popover .gtr-content {
  padding: 0px !important;
  border-radius: 6px;
}

// For Equipment Cards
.terminal-list-wrapper {
  max-height: 42rem;
  overflow-y: auto;
  overflow-x: none;
}

.terminal-card {
  position: relative;
  width: 100%;
  background: #fff;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: none;
  margin-bottom: 2rem;

  .card-header {
    min-height: 5rem;
    max-height: 5rem;
    padding: 0.8rem 1.8rem 0 0.8rem;

    h5 {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 4px;
      color: #000;
    }

    h6 {
      font-size: 12px;
      font-weight: 400;
      color: #000;
    }
  }

  .card-body {
    padding: 0.8rem;
    padding-top: 0;

    .img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 4px;

      &.gateway {
        min-height: 90px;
      }

      img {
        width: auto;
        // height: 130px;
        // object-fit: contain;
        max-width: 150px;

        &.other {
          height: 130px;
          object-fit: contain;
          width: 50%;
        }
      }
    }

    .plus {
      padding: 4px;
    }

    .plus,
    .monthly-cost {
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      color: #000;
    }

    .amount-wrapper {
      display: flex;
      padding: 7px 10px;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      background: #009e47;
      color: #fff;
      width: fit-content;
      border-radius: 5rem;
    }
  }

  .card-footer {
    padding: 0.8rem;
    padding-top: 6px;
    border-top: solid 1px rgba(0, 0, 0, 0.1);

    p {
      font-size: 13px !important;
      margin: 0;
      line-height: 21px;
      color: #000;

      span {
        font-weight: 700;
      }
    }
  }

  .cancel-icon {
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
    cursor: pointer;
    color: rgba(75, 70, 92, 0.5);
  }
}

app-offer-banner {
  #sd-ofr {
    .offer_content_web.ofr-bnr-visualize {
      background: var(--ofr-bnr-primary-clr) !important;
      color: var(--ofr-bnr-text-clr) !important;
    }

    .new_offer_content_web.ofr-bnr-visualize {
      background: var(--ofr-bnr-primary-clr) !important;
      color: var(--ofr-bnr-text-clr) !important;
    }

    .offers-outer-wrapper.ofr-bnr-visualize-scroll::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: var(--ofr-bnr-primary-clr) !important;
    }
  }
}

//Prospect Upload CSS
.prospects-uploads {
  h4 {
    font-size: 18px;
    margin: 0;
  }

  .documents-required-wrapper {
    border-radius: 6px;
    border: 1px solid #ececec;
    background: #f9f9f9;

    ol {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
      }

      @media only screen and (max-width: 992px) {
        grid-template-columns: 1fr 1fr;
      }

      @media only screen and (max-width: 600px) {
        grid-template-columns: none;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    gtr-button .btn-text {
      display: none;
    }
  }
}

// state error

.is-invalid .multiselect-dropdown {
  border-bottom: 1px solid red;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

app-terminal-card,
app-select-hardware,
physical-terminal-card {
  .excluded-price {
    text-decoration-line: line-through;
    text-decoration-thickness: 1.5px;
    text-decoration-color: #000 !important;
  }

  gtr-badge.excluded-price .gtr-badge {
    text-decoration-line: line-through;
    text-decoration-thickness: 1.5px;
    text-decoration-color: #000 !important;
  }
}


// auth pages shapes

.shapes__form-container {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.2);
  padding: 45px 50px 25px 50px;
  position: relative;
  z-index: 12;

  @media only screen and (max-width: 480px) {
    padding: 45px 40px 40px 40px;
    margin: 0 -16px;
  }
}

.shapes__form-container.password {
  padding: 45px 50px 40px 50px;
}

.relative {
  position: relative;
  margin-top: 25px;
}

.shape {
  position: absolute;
}

// container left shapes css
.shape.rect__left-shape-1 {
  width: 300.98px;
  height: 300.98px;
  transform: rotate(-32.643deg);
  background: var(--primary-color);
  bottom: 10%;
  z-index: 0;
  left: -18%;
}

.shape.rect__left-shape-2 {
  width: 190.5px;
  height: 152.5px;
  transform: rotate(-7.785deg);
  background: var(--secondary-color);
  bottom: -9%;
  z-index: 1;
  left: -13%;
}

.shape.rect__left-shape-3 {
  width: 138.153px;
  height: 103.153px;
  transform: rotate(27.675deg);
  background: rgba(var(--secondary-color-var), 0.6);
  bottom: -21%;
  z-index: 2;
  left: 0%;
}

.shape.rect__left-shape-4 {
  width: 94.761px;
  height: 94.761px;
  transform: rotate(97.639deg);
  background: rgba(var(--primary-color-var), 0.6);
  bottom: 7%;
  z-index: 0;
  left: -20%;
}

.shape.rect__left-shape-5 {
  width: 41.298px;
  height: 51.298px;
  transform: rotate(33.521deg);
  background: rgba(var(--primary-color-var), 0.2);
  bottom: 25%;
  z-index: 0;
  left: -40%;
}

// container right shapes css
.shape.rect__right-shape-1 {
  width: 240.14px;
  height: 189.14px;
  transform: rotate(-11.605deg);
  background: var(--primary-color);
  top: -6%;
  z-index: 0;
  right: -13%;
}

.shape.rect__right-shape-2 {
  width: 100.426px;
  height: 100.426px;
  transform: rotate(27.675deg);
  background: var(--secondary-color);
  top: 15%;
  z-index: 1;
  right: -9%;
}

.shape.rect__right-shape-3 {
  width: 94.206px;
  height: 94.206px;
  transform: rotate(11.104deg);
  background: rgba(var(--secondary-color-var), 0.6);
  top: 30%;
  z-index: 0;
  right: -10%;
}

.shape.rect__right-shape-4 {
  width: 43.855px;
  height: 53.855px;
  transform: rotate(66.681deg);
  background: rgba(var(--secondary-color-var), 0.2);
  top: 27%;
  z-index: 0;
  right: -30%;
}

.shape.rect__right-shape-5 {
  width: 80.565px;
  height: 62.565px;
  transform: rotate(27.675deg);
  background: rgba(var(--primary-color-var), 0.3);
  top: 10%;
  z-index: 0;
  right: -28%;
}

.auth-card-content {
  padding: 40px 50px;

  @media screen and (max-width: 992px) {
    padding: 30px 0 20px;
  }
}

.auth-card-header {
  border-radius: 20px 20px 0 0;
  background-color: #F5F5F5;
  margin: -45px -50px 0 -50px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  box-shadow: 0 -20px 10px -25px rgba(0, 0, 0, 0.3) inset;

  @media only screen and (max-width: 480px) {
    margin: -45px -40px 0 -40px;
  }

  .header-tab {
    flex: 1 1 0px;
    border-radius: 25px 25px 0 0;
    padding: 25px 15px;
    text-align: center;
    cursor: pointer;
    position: relative;
    color: var(--primary-color);

    @media screen and (max-width: 768px) {
      text-align: left;
      padding: 20px;
    }

    &:after,
    &:before {
      content: '';
      width: 50px;
      height: 50px;
      position: absolute;
      bottom: 0;
      border-top: 0;
      background: transparent;
      pointer-events: none;
    }

    &:after {
      border-left: 0;
      -moz-border-radius: 0 0 50% 0;
      -webkit-border-radius: 0 0 50% 0;
      border-radius: 0 0 50% 0;
      left: -50px;
      box-shadow: -25px -25px 10px -25px rgba(0, 0, 0, 0.06) inset, 0 25px 0 0 #fff;
    }

    &:before {
      border-right: 0;
      -moz-border-radius: 0 0 0 50%;
      -webkit-border-radius: 0 0 0 50%;
      border-radius: 0 0 0 50%;
      right: -50px;
      box-shadow: 25px -25px 10px -25px rgba(0, 0, 0, 0.06) inset, 0 25px 0 0 #fff;
    }


    &.active {
      background-color: #fff;
      box-shadow: 0 -25px 25px 10px rgba(0, 0, 0, 0.03);
      color: inherit;
      z-index: 5;
    }
  }
}

app-auth-login,
app-auth-entity-login {
  .shape.rect__right-shape-1 {
    top: -8%;
  }

  .shape.rect__right-shape-2 {
    top: 17%;
  }

  .shape.rect__right-shape-3 {
    top: 39%;
  }

  .shape.rect__right-shape-4 {
    top: 35%;
  }
}

app-auth-forgot-password,
app-auth-reset-password,
app-auth-entity-forgot-password,
app-auth-entity-reset-password,
app-auth-entity-set-password {
  .shape.rect__left-shape-1 {
    width: 220.98px;
    height: 220.98px;
  }

  .shape.rect__left-shape-2 {
    width: 152.5px;
    height: 135.5px;
    bottom: -3%;
  }

  .shape.rect__right-shape-1 {
    top: -10%;
  }

  .shape.rect__right-shape-2 {
    top: 31%;
  }

  .shape.rect__right-shape-3 {
    top: 50%;
  }

  .shape.rect__right-shape-4 {
    top: 47%;
  }

  .shape.rect__right-shape-5 {
    top: 20%;
  }
}

app-auth-entity-reset-password,
app-auth-entity-set-password,
app-auth-reset-password {
  .shape.rect__right-shape-2 {
    top: 25%;
  }

  .shape.rect__right-shape-3 {
    top: 45%;
  }

  .shape.rect__right-shape-5 {
    top: 20%;
    right: -33%;
  }
}

@media only screen and (max-width: 992px) {
  .relative {
    margin-top: 15%;
  }
}

// signin link css for set password page for regular and entity
.password-already-set {
  margin-top: 22px;
  display: flex;
  flex-direction: column;

  p.set-text {
    text-align: center;
  }

  span.signin-link {
    text-align: center;
  }

  span.signin-link a {
    color: var(--primary-color);
    font-size: 1rem !important;

    &:hover {
      text-decoration: underline;
    }
  }
}

// Overide z index of ngx-color-overlay (Reason for adding this is because whenever we're adding color input inside modal color picker was hiding) 
.ngx-colors-overlay {
  z-index: 9999 !important;
}