// ================================================================================================
// 	File Name: helper.scss
// 	Description: Helper classes provides template related customization.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Vuexy - Vuejs, React, Angular, HTML & Laravel Admin Dashboard Template
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Content helpers
// -------------------------

// typography page icon with list
.list-style-icons {
  padding-left: 10px;
  margin-left: 0;
  list-style: none;

  >li svg,
  >li i {
    margin-right: 6px;
  }
}

//toaster hover css
.toast-container .ngx-toastr:hover{
  box-shadow: 0 0 12px #999999 !important;
}

//Pull the element
.pull-up {
  transition: all 0.25s ease;

  &:hover {
    transform: translateY(-4px) scale(1.02);
    box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
    z-index: 30;
  }
}

.btn {
  font-weight: 500;
  padding: 0.786rem 1.5rem;
  line-height: 1;
}

.p-xl-4 {
  padding: 3.5rem!important;
}

.gap-1 {
  gap: 1rem !important;
}

.gap-2 {
  gap: 1.5rem !important;
}

.card-body {
  padding: 1.5rem 1.5rem;
}

.toast-top-right {
  top: 1.3rem !important;
  right: 1rem !important;
}

// Spinner classes
.spinner {
  display: inline-block;
  animation: spin 1s linear infinite;
}

gtr-input-group input {
  opacity: 0.6;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner-reverse {
  display: inline-block;
  animation: spin-reverse 1s linear infinite;
}

@keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

// Bullets used in application pages
.bullet {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: inline-block;

  &.bullet-xs {
    width: 0.5rem;
    height: 0.5rem;
  }

  &.bullet-sm {
    width: 0.714rem;
    height: 0.714rem;
  }

  &.bullet-lg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

// Section Label - used in pages and apps
.section-label {
  font-size: 0.85rem;
  // color: $text-muted;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}

// Used for table cell fit
.cell-fit {
  width: 0.1%;
  white-space: nowrap;
}

// Card match height
.match-height {
  >[class*='col'] {
    display: flex;
    flex-flow: column;

    >.card {
      flex: 1 1 auto;
    }
  }
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --gtr-gutter-x: 2rem !important;
}

.payment-form .ps-xl-2 {
  padding-left: 1.5rem !important;
}

.p-lg-2 {
  padding: 1.5rem !important;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1200px !important;
  }
}

@media (min-width: 1440px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1440px !important;
  }
}

@media (min-width: 1200px) {
  .pt-xl-1 {
      padding-top: 1rem !important;
  }
}

@media (min-width: 1440px) {
  .pt-xxl-4 {
      padding-top: 3.5rem !important;
  }
}
