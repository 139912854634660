// Perfect scrollbar
@import 'om-perfect-scrollbar/css/perfect-scrollbar.css';

// Core styles
@import './base/bootstrap';
@import './base/bootstrap-extended';
@import './base/colors';
@import './base/components';
@import './base/core/colors/palette-gradient';
@import './base/plugins/forms/form-validation'; // Added for form validation style support

// Vue Specific
@import './angular/index';
