$bg-pre: #282c34;

pre {
  background-color: $bg-pre !important;
  border-radius: 0.3rem;

  code {
    font-family: monospace;
  }
}

.hljs {
  font-size: 1.15rem;
}

// ng form validation error
.error {
  ~ .input-group-append {
    .input-group-text {
      border-color: $danger !important;
    }
  }
}

//! Fix: PS Scroll sometimes appear on right side issue
.main-menu .main-menu-content .ps__rail-y {
  right: 0;
  left: inherit !important;
}
