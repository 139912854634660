// accordion icon animation
.collapse-icon {
  [aria-expanded='true'],
  [aria-expanded='false'] {
    &:after {
      content: '';
      position: absolute;
      background-image: url(str-replace(str-replace($chevron-up, 'currentColor', $body-color), '#', '%23'));
      background-size: 14px;
      top: 58%;
      right: 1rem;
      width: 14px;
      height: 11px;
      margin-top: -8px;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 300ms linear 0s;
      color: #6e6b7b;
    }
  }
}
